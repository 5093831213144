.answer-list table {
	border-collapse: collapse;
	width: 100%;
}
.answer-list tr:nth-child(2n + 1) {
	background: none !important;
}

.answer-list td {
	padding: 0.3rem 0.8rem;
}

.answer-list td:last-of-type {
	text-align: start;
}

.answer-list button.answer {
}
.answer-list .answerContent {
}

.answer-list button.answer:hover {
	opacity: 0.8;
}
