#helpWrapper {
	margin: 2em auto 0;
	display: none;
}

#help {
	border-radius: 0.4em;
	padding: 0.1em 1em;
	background: #e7f8e1;
	border: 1px solid #9fbd94;
	position: relative;
}

#help blockquote {
	font-style: italic;
	font-size: 95%;
	opacity: 0.95;
	border-left: 4px solid #4b4b66;
	margin-left: 0.3em;
	padding-left: 0.6em;
}

#helpWrapper.active {
	display: block;
}

#help #closeHelp {
	font-size: 150%;
	position: absolute;
	top: 0.4em;
	right: 0em;
	cursor: pointer;
}

#help h3 {
	margin: 0.3em 0;
}
