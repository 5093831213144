.currencyInput__container {
	display: flex !important;
	align-items: center;
	justify-content: flex-end;
}

.currencyInput__input:focus {
	outline: none;
}
.currencyInput__input {
	height: inherit;
	max-height: inherit;
	border: none;
	text-align: inherit;
	font-family: inherit;
	padding: 0;
	font-weight: inherit;
	min-width: 0;
	outline: none;
	margin: 0;
	width: inherit;
	color: inherit;
	background-color: transparent;
	font-size: inherit;
}

.currencyInput__input::-ms-clear {
	display: none;
}
