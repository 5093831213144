.ui__.toggle {
	border: 1px solid var(--color);
	border-radius: 0.3rem;
	overflow: hidden;
	display: flex;
}
.ui__.toggle label {
	padding: 0.6rem 1.2rem;
	color: var(--color);
	text-transform: uppercase;
	cursor: pointer;
}
.ui__.toggle.small label {
	font-size: 80%;
	overflow: hidden;
	padding: 0.4rem 0.8rem;
}
.ui__.toggle label:not(:last-child) {
	border-right: 1px solid var(--color);
}

.ui__.toggle input[type='radio']:checked ~ * {
	background: var(--lightColor);
	box-shadow: 0 0 0 3rem var(--lightColor);
	color: white;
}

.ui__.toggle label:hover input[type='radio']:not(:checked) ~ * {
	background: var(--lighterColor);
	box-shadow: 0 0 0 1rem var(--lighterColor);
}

.ui__.toggle input[type='radio']:focus ~ .radioText {
	border: 1px dotted gray;
}
.ui__.toggle input[type='radio']:checked ~ .radioText {
	font-weight: 600;
}

.ui__.radio input[type='radio'] ~ *::before {
	display: inline-block;
	overflow: show;
	width: 0.8em;
	height: 0.8em;
	content: '';
	will-change: box-shadow;
	margin: 0.06em;
	margin-right: 0.4em;
	vertical-align: middle;
	border-radius: 100%;
	cursor: pointer;
	box-shadow: 0 0 0px 2px rgb(41, 117, 209);
	box-shadow: 0 0 0px 2px var(--color);
	transition: all 0.1s;
	border: 0.4em solid white;
}

.ui__.radio input[type='radio']:focus ~ *::before {
	box-shadow: 0 0 0px 2px var(--color), 0 0 5px 3px var(--lightColor);
}

.ui__.radio input[type='radio']:focus ~ span .radioText {
	border-bottom: 1px solid var(--lightColor);
}

.ui__.radio input[type='radio']:checked:focus ~ span .radioText {
	border-bottom: 1px solid white;
}

.ui__.radio input[type='radio'] {
	position: fixed;
	left: -100vw;
}

.ui__.radio input[type='radio'] ~ * {
	cursor: pointer;
	display: flex;
	align-items: center;
	transition: all 0.1s;
}
.ui__.radio input[type='radio']:checked ~ *::before {
	border: 2px solid white;
	background: var(--color);
}
