blockingNotification {
	text-align: center;
	font-size: 150%;
}

#notificationsBlock > ul {
	list-style: none;
	padding: 0;
}
#notificationsBlock .notification {
	margin: 1em 0;
	display: flex;
	align-items: center;
}
#notificationsBlock .notificationText {
	width: 100%;
	padding: 1rem 2.6rem 1rem 1.6rem;
	/*For the .hide element */
	position: relative;
}
#notificationsBlock .notificationText p:last-child {
	margin: 0;
}

.notificationText .hide {
	position: absolute;
	top: 0rem;
	right: -1.4rem;
	font-size: 200%;
}

#notificationExplanation {
}
#notificationExplanation > div {
	display: inline;
}

/*Disable links visually */
#notificationExplanation a {
	color: inherit;
	text-decoration: none;
}

/* Display the values of the variables in the explanation of the failed control */
#notificationsBlock .variable .situationValue {
	display: inline-block;
}
#notificationsBlock img {
	margin: 0 1em 0 !important;
	width: 1.6em !important;
	height: 1.6em !important;
}
