.step fieldset {
	justify-content: flex-end;
	flex-wrap: wrap;
	align-items: baseline;
}

.step fieldset ul {
	list-style-type: none;
}

.step fieldset .step.question .variantLeaf,
.step fieldset .step.question {
	justify-content: flex-end;
}

.step.question .variant {
	margin-bottom: 1em;
}

.step.question .variant > ul {
	border-right: 1px dashed #333;
	text-align: right;
	padding-right: 0.6em;
	padding-top: 0.6em;
	padding-left: 2em;
	margin-left: 1rem;
}
.step.question .variant > div {
	padding-right: 0.6em;
	font-weight: 600;
	text-align: right;
}

.step.question fieldset span {
	white-space: nowrap;
}
.step.question .variantLeaf {
	display: flex;
}

.step.question .variant .variantLeaf {
	display: inline-flex;
	align-items: center;
}

.step.question .variant .variantLeaf:not(:first-child) {
	margin-left: 0.6rem;
}
.step.question .variantLeaf.aucun label {
	font-weight: 800;
	text-decoration: underline;
}

.step .input > :first-child {
	text-align: right;
}

.step label.suffix {
	vertical-align: baseline;
	transition: color 0.1s;
	margin-left: 0.6rem;
}
.step label.userAnswerButton {
	margin-bottom: 0.6rem;
	background: none;
	border: 1px solid var(--color) !important;
	text-transform: none !important;
	background-color: white;
	color: var(--textColorOnWhite) !important;
}
.step label.userAnswerButton.selected {
	background: var(--lightColor);
	color: var(--textColor) !important;
}

@media print {
	.step label.userAnswerButton.selected {
		border: none;
	}

	.step label.userAnswerButton:not(.selected) {
		display: none;
	}
}
@media (hover) {
	.step label.userAnswerButton:hover:not(.selected) {
		background: var(--lighterColor);
		transition: all 0.05s;
	}
}
